import React from "react";
import Layout from "../components/layout"
import Helmet from 'react-helmet';

const Post = ({ data }) => {

	let canonicalLink;
	if (data.contentfulPage.canonicalUrl !== null) {
		canonicalLink = <link rel="canonical" href={data.contentfulPage.canonicalUrl} />
	} else {
		canonicalLink = undefined
	}

	return (
		<Layout>
			<div>
				<Helmet title={'Martzoukos - ' + data.contentfulPage.title}>
			  	{canonicalLink}
				</Helmet>
				<nav style={{ marginBottom: `2em`, maxWidth: 650 }}>
		  		<a href="/" style={{ fontSize: '14px' }}>← Back home</a>
		  	</nav>
				<h1>{data.contentfulPage.title}</h1>
				<div style={{ fontSize: '14px' }}>Created at: <time>{data.contentfulPage.createdAt}</time></div>
				<div dangerouslySetInnerHTML={{__html: data.contentfulPage.content.childContentfulRichText.html}} />

			</div>
		</Layout>
	)
}

export default Post

export const PostQuery = graphql`
	query Post($id: String!) {
	  contentfulPage(id: { eq: $id }) {
	    title
	    canonicalUrl
	    content {
        childContentfulRichText {
          html
        }
	    }
	    createdAt(formatString: "MMMM Do, YYYY")
	  }
	}
`;

